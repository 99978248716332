import React, { useState } from 'react';

const CookieConsentBanner = () => {
  const [consentAccepted, setConsentAccepted] = useState(false);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setConsentAccepted(true);
  };

  if (consentAccepted || localStorage.getItem('cookieConsent')) {
    return null; // Hide the banner once consent is given or stored
  }

  return (
    <div className="cookie-banner">
      <p className="cookie-banner-title" >Datenschutz-Einstellungen</p>
      <p className="cookie-banner-description">Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien anbieten zu können und die Zugriffe auf unsere Website zu analysieren.</p>
      <div className='cookie-banner-button-container'>
        <button onClick={handleAccept}>Alle akzeptieren</button>
        <button onClick={() => window.location.href = '/datenschutz'}>Mehr erfahren</button>
      </div>
    </div>
  );
};

export default CookieConsentBanner;
