import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ContactFormPage from './pages/ContactFormPage/ContactFormPage'; // Import your ContactFormPage component
import Home from './pages/Home/Home';
import DataProtection from './pages/DataProtection/DataProtection';
import CookieConsentBanner from './components/CookieConsentBanner';

function App() {
  return (
    <div className="App">
      <CookieConsentBanner />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/form" element={<ContactFormPage />} />
        <Route path="/datenschutz" element={<DataProtection />} />
      </Routes>
    </div>
  );
}

export default App;
