import React from 'react';
import BrandName from '../../components/BrandName';
import Form from '../../components/Form';
import Footer from '../../components/Footer';

function ContactFormPage(){
  
    return (
        <div className="app">
        <div className="wrapper">
          <BrandName />
          <Form/>
          <div className="main-content">
            {/* Your main content goes here */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }

export default ContactFormPage;
