import React, { useState } from 'react';
import axios from 'axios';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
    consent: false, // Add a new field for consent
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send the form data to the backend
      const response = await axios.post('/submit', formData);
      console.log('Form submitted:', formData);
      console.log('Server response:', response.data);

      // Reset form fields
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        message: '',
        consent: false, // Reset consent field
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div>
    <div className="form">
      <h2 className="form-title">Kontakt aufnehmen</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-field">
          <label htmlFor="firstName">Vorname</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </div>
        <div className="form-field">
          <label htmlFor="lastName">Nachname</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>
        <div className="form-field">
          <label htmlFor="email">Email*</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-field">
          <label htmlFor="message">Nachricht*</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-field form-checkbox">
            <label>
                <input
                type="checkbox"
                name="consent"
                id="consent"
                checked={formData.consent}
                onChange={handleChange}
                required
                />
                <span>Ich willige in die Verarbeitung meiner personenbezogenen Daten zum Zwecke der Kontaktaufnahme zur Information über Produkte und Dienstleistungen durch influbo ein.</span>
            </label>
        </div>
        <p className="additional-text">
            Sie haben das Recht, Ihre Einwilligung jederzeit zu widerrufen. Dadurch wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt. Weitere Informationen finden Sie in unserer{" "}
            <a href="/datenschutz" className="privacy-link">
            Datenschutzerklärung
            </a>
        </p>
        <button className="get-started-button" type="submit">
          Senden
        </button>
      </form>
    </div>
    </div>
    
  );
};

export default ContactForm;
