import React, { useState } from 'react';

const FAQ = () => {
  const [expanded, setExpanded] = useState(null);

  const toggleItem = (index) => {
    if (expanded === index) {
      setExpanded(null);
    } else {
      setExpanded(index);
    }
  };

  const faqItems = [
    {
      question: 'Was macht der Service?',
      answer: 'Der Service hilft dir dabei die Interaktion mit Fans und Followern durch den Verkauf von individuellen Sprachnachrichten zu monetarisieren. Du bestimmst den Preis, und alle Gewinne, die der Telegram Bot generiert, gehören dir.'
    },
    {
      question: 'Ist der Service auf allen Plattformen verfügbar?',
      answer: 'Ja, der Service ist auf iOS und Android verfügbar, da die Lösung auf der Telegram-App basiert.'
    },
    {
      question: 'Gibt es eine Begrenzung für die Anzahl der Änderungen, die ich stellen kann?',
      answer: 'Mit unserem Abonnement kannst du so viele Softwareentwicklungsanfragen stellen, wie du möchtest, und wir werden sie in der Reihenfolge bearbeiten.'
    },
    {
      question: 'Warum sollte ich nicht einfach einen Vollzeit-KI-Softwareentwickler einstellen?',
      answer: 'Die Kosten für die Einstellung eines Vollzeit-Senior-KI-Softwareentwicklers liegen in der Regel bei mehr als 160.000 Euro pro Jahr, zuzüglich zusätzlicher Leistungen. Außerdem hast du möglicherweise nicht immer genügend Projekte, um sie zu beschäftigen, was dazu führt, dass du für ungenutzte Zeit bezahlst.'
    },
    {
      question: 'Wie schnell wird meine KI-Integration entwickelt?',
      answer: 'Im Allgemeinen werden die meisten Anfragen innerhalb 10 Tage bearbeitet. Bei komplexeren Anfragen kann jedoch mehr Zeit erforderlich sein.'
    },
    {
      question: 'Wer sind die KI-Entwickler?',
      answer: 'Es könnte interessant für dich sein, dass Influbo derzeit von einem Entwickler betrieben wird. Dies ermöglicht eine schnelle und unkomplizierte Kommunikation. Bei komplexeren Anforderungen wie benutzerdefinierten Machine-Learning-Modellen oder maßgeschneiderten Algorithmen arbeitet Influbo jedoch mit Partnern zusammen.'
    },
    {
      question: 'Wie funktioniert die Funktion zur Unterbrechung des Abonnements?',
      answer: 'Wir erkennen an, dass du möglicherweise nicht jeden Monat das Abonnement zahlen kannst. Die Pause-Funktion kommt in solchen Szenarien ins Spiel. Die Abrechnungszyklen basieren auf einem 31-Tage-Zeitraum. Wenn du dich beispielsweise anmeldest und den Service 21 Tage lang nutzt und dann beschließt, dein Abonnement zu pausieren, wird der Abrechnungszyklus pausiert, und du hast noch 10 verbleibende Servicetage. Dein persönlicher Bot ist zu dieser Zeit dann auch nicht erreichbar für deine Fans'
    },
    {
      question: 'Was passiert, wenn ich mit den Ergebnissen nicht zufrieden bin?',
      answer: 'Keine Sorge! Wir werden die Software weiter verfeinern und modifizieren, bis sie deinen Anforderungen vollständig entspricht. Mit unserer Rückerstattungsgarantie stellen wir sicher, dass du zufrieden bist.'
    }
  ];
  

  return (
<div>
 <div className="section">  
  <h2 className="section-title">Häufig gestellte Fragen</h2>
  </div>
  <div className="faq">
    <ul>
      {faqItems.map((item, index) => (
        <li key={index} className={`faq-item ${expanded === index ? 'expanded' : ''}`}>
          <h3 className="question" onClick={() => toggleItem(index)}>
            <span className={`faq-icon ${expanded === index ? 'minus' : 'plus'}`}></span>
            {item.question}
          </h3>
          {expanded === index && <p className="answer">{item.answer}</p>}
        </li>
      ))}
    </ul>
  </div>
</div>

  );
};

export default FAQ;
