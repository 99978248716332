import React from 'react';
import { useNavigate } from 'react-router-dom';
import BrandName from '../../components/BrandName';
import Header from '../../components/Header';
import ServiceDescription from '../../components/ServiceDescription';
import PaymentDescription from '../../components/PaymentDescription';
import FAQ from '../../components/FAQ';
import Footer from '../../components/Footer';

function Home() {
  const navigate = useNavigate();

  function goToContactForm() {
    navigate('/form');
  }

  return (
        <div className="app">
          <div className="wrapper">
            <BrandName />
            <Header onGetStarted={goToContactForm}/>
            <ServiceDescription onGetStarted={goToContactForm} />
            <PaymentDescription />
            <FAQ />
            <div className="main-content">
              {/* Your main content goes here */}
            </div>
            <Footer />
          </div>
        </div>
  );
}

export default Home;
