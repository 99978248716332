import React from 'react';

const Footer = () => (
  <footer className="footer">
    <div className="footer-links">
      <a href="https://www.freepik.com/free-photo/portrait-young-blonde-woman-posing_9723984.htm#query=long%20blonde%20hair&position=6&from_view=keyword&track=ais" className="footer-link">Image by wayhomestudio on Freepik</a>
    </div>
    <br></br>
    <div className="footer-links">
      <a href="/datenschutz" className="footer-link">Impressum & Datenschutz</a>
    </div>
    <p className="footer-text">Copyright &copy;2023 Influbo Technologies. All Rights Reserved</p>
  </footer>
);

export default Footer;
