// src/components/BrandName.js
import React from 'react';

function BrandName() {
    return (
      <a href="/" className="brand-link"> 
        <h1 className="brand-name">influbo</h1>
      </a>
    );
  }

export default BrandName;
