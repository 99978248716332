import React from 'react';

const ServiceDescription = ({ onGetStarted }) => {
    return (
      <div className="screenshot">
        <img className="right-margin" src="telegram.png" alt="App Screenshot" />
        <div className="screenshot-content">
            <p className="screenshot-title">Dein AI Clone</p>
            <p className="screenshot-description">
            Schaffe ein einzigartiges Fan-Engagement mit deinem persönlichen Sprachassistenten!
            </p>
            <p className="screenshot-description">
            Erstelle deinen eigenen virtuellen Assistenten, der in deiner Stimme antwortet und eine starke Bindung zu deinen Fans schafft. Der Telegram-Bot übernimmt Nachrichten, damit du dich auf Content und Privates konzentrieren kannst. 
            </p>
            <p className="screenshot-description">
            Durchschnittlich dauert die Lieferung des KI-Chatbots 10 Tage. Wir garantieren, dass wir Überarbeitungen durchführen, bis Du zufrieden bist. Du hast die Freiheit, jederzeit zu kündigen.
            </p>
            <p>
              <span className="discounted-price">299,00€</span><span className="screenshot-description-bold"> / Monat</span>
            </p>
            <button className="get-started-button" onClick={onGetStarted}>
              Kostenlos testen
            </button>
        </div>
      </div>
    );
  }
  
  export default ServiceDescription;
