import React from 'react';

function PaymentDescription() {
    return (
      <div className="screenshot">
        <div className="screenshot-content">
            <p className="screenshot-title">Zusätzliche Einkommensquelle</p>
            <p className="screenshot-description">
            Generiere zusätzliches Einkommen und biete Fans rund um die Uhr Interaktion.
            </p>
            <p className="screenshot-description">
            Dein Geld verdienen leicht gemacht: Deine Fans und Follower können individuelle Nachrichten von dir erwerben, und du bestimmst den Preis. Jeder Erlös, den der Telegram Bot generiert, fließt direkt in deine Tasche. 
            </p>
            <p className="screenshot-description">
            Die Bezahlung erfolgt unkompliziert über verschiedene Optionen, wie Debitkarte oder Apple Pay. Mit dieser smarten Möglichkeit, deine Interaktionen zu monetarisieren, steht deinem Einkommen nichts mehr im Weg.
            </p>
        </div>
        <img className="left-margin" src="payment.png" alt="App Screenshot" />
      </div>
    );
  }
  
  export default PaymentDescription;
