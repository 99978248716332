import React from 'react';

const Header = ({ onGetStarted }) => {
  return (
    <header className="header">
      <h1>AI Cloning Service für Creator und Influencer</h1>
      <p className="subtext">Monetarisiere die Interaktion mit Fans und Follower mit generierten Sprachnachrichten</p>
      <button className="get-started-button" onClick={onGetStarted}>
        Kostenlos testen
      </button>
    </header>
  );
};

export default Header;
